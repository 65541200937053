<template lang="pug">
    base-layout
        template(slot="main")
            login-form 
</template>

<script>
//Components
import BaseLayout from '@/layout/base-layout';
import LoginForm from '@/components/views/login/';

export default {
    name: "login",
    components: {
        'login-form': LoginForm,
        'base-layout': BaseLayout,
    }
}
</script>