<template lang="pug">
    .flex.flex-col.gap-5.w-300.p-4
        .flex.flex-col
            label(for="email") {{FORM_LABELS.email}}
            input.border-1.h-10(type="text" id="email" v-model="email")
        .flex.flex-col
            label(for="password") {{FORM_LABELS.password}}
            input.border-1.h-10(type="password" id="password" v-model="password")
        button.bg-gray-300.w-100.p-2(@click="onLogin($data)") {{ FORM_BUTTONS.send }}
</template>

<script>
//Store
import { AuthorizationNameSpace, AuthorizationActionTypes } from '@/store/authorization/types';
//Config
import { FORM_LABELS, FORM_BUTTONS } from '@/configs/forms'

export default {
    name: "login-form",
    data() {
        return {
            email: '',
            password: '',
        }
    },
    computed: {
        FORM_LABELS() {
            return FORM_LABELS
        },
        FORM_BUTTONS() {
            return FORM_BUTTONS
        },
    },
    mounted() {
        if (localStorage.getItem('email')) {
            this.onLoginAfterRegistration()
            localStorage.removeItem('email')
        }
    },
    methods: {
        onLogin({ email, password }) {            
            this.$store.dispatch(`${AuthorizationNameSpace}/${AuthorizationActionTypes.Login}`, { email, password }).then(({data}) => {
                if (data.error_message) { this.email = '', this.password = '' }
                else {
                    const countUrl = localStorage.getItem('countUrl')
                    if (countUrl) {
                        this.$router.push(countUrl)
                        localStorage.removeItem('countUrl')
                    } else {
                        this.$router.push('/account')
                    }
                }
            })
        },
        onEmail(value) {
            this.email = value
        },
        onPassword(value) {
            this.password = value
        },
        onLoginAfterRegistration() {
            this.email = localStorage.getItem('email')
        },
    }
}
</script>